import React, { useContext, useEffect, useState } from "react";
import { HomeContext } from "../../App";

const HomeCTA = () => {
  const data = useContext(HomeContext);
  const ctaSectionContent = data?.customization?.CTASection?.content;
  const ctaSectionSty = data?.customization?.CTASection?.style;
  const ctaImg = data?.section3_img;

  function HomeCTAStyle() {
    const style = document.createElement("style");
    style.textContent = `
    .home_cta_content .get_started{
      background: ${ctaSectionSty?.ctaBg};
      color: ${ctaSectionSty?.ctaTextColor};
      box-shadow: 0 0 5px 0 ${ctaSectionSty?.ctaShadow};
    }
    .home_cta_content .get_started:hover{
      background: ${ctaSectionSty?.ctaHoverBg};
      box-shadow: 0 0 10px 0 ${ctaSectionSty?.ctaHoverShadow};
      color: ${ctaSectionSty?.ctaHoverTextColor};
    }
    `;
    document.head.appendChild(style);
  }
  useEffect(() => {
    HomeCTAStyle();
  }, [ctaSectionSty]);

  return (
    <>
      <div
        className="home_cta_wrapper"
        style={{ background: ctaSectionSty?.bgColor }}
      >
        <div className="container">
          <div className="row align-items-lg-center">
            <div className="col-lg-6">
              <div className="home_cta_image">
                <img
                  src={`static/assets/images/${
                    ctaImg ? ctaImg : "cta_img.png"
                  }`}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="home_cta_content">
                <h2 style={{ color: ctaSectionSty?.headingColor }}>
                  {ctaSectionContent?.headingText}
                </h2>
                <p style={{ color: ctaSectionSty?.paraColor }}>
                  {ctaSectionContent?.paraText}
                </p>
                <a className="get_started" href="#">
                  {ctaSectionContent?.CTAbuttonText}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeCTA;
